<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>STOCK</h4>
        <app-table-registers :getList="getList" ref="tr">
          <template slot="tl">
            <div class="col">
              <div class="btn-group">
                <!-- <button
                  class="btn btn-primary"
                  @click="$refs.dFormStore.show(); $refs.formStore.reset();"
                >
                  <i class="fa fa-plus"></i> Agregar Almacen
                </button>
                <button
                  class="btn btn-primary"
                  @click="$refs.dFormAddStock.show(); $refs.formToAdd.reset();"
                >
                  <i class="fa fa-plus"></i> Registrar Stock
                </button>-->
              </div>
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th>COD.</th>
                <th></th>
                <th>PRODUCTO</th>
                <th v-for="l in stores" :key="l.id">{{ l.name }}</th>
                <th v-show="config.see_total">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ l.product_id }}</td>
                <td>
                  <app-img-viewer
                    v-if="l.product_file_id"
                    :fileId="l.product_file_id"
                  ></app-img-viewer>
                </td>
                <td>
                  <div>{{ l.product_name }}</div>
                  <span class="badge badge-light" v-show="l.brand_name"
                    >{{ l.brand_name }}
                  </span>
                </td>
                <td v-for="l1 in stores" :key="l1.id">
                  <SpanStock
                    v-if="l.stocks[l1.id]"
                    :stock="l.stocks[l1.id]"
                    @clickTransfer="
                      $refs.dFormTransGoods.show();
                      $refs.formTransGood.loadStock(l, l1.id);
                    "
                    @clickUse="
                      $refs.dFormUse.show();
                      $refs.formUse.loadStock(l, l1.id);
                    "
                  ></SpanStock>
                  <span v-else>0</span>
                </td>
                <td v-show="config.see_total">
                  <span>{{ l.quantity }}</span>
                  <button
                    v-if="config.can_reconfig_stock"
                    class="btn btn-light btn-sm ml-2"
                    @click="
                      $refs.dConfSto.show();
                      $refs.confStock.loadReg(l);
                    "
                  >
                    <i class="fa fa-cog"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="dFormStore">
      <FormStore
        ref="formStore"
        @submitted="$refs.dFormStore.hide()"
      ></FormStore>
    </app-modal-basic>

    <app-modal-basic ref="dFormAddStock">
      <FormAddStock
        ref="formToAdd"
        @submitted="
          $refs.dFormAddStock.hide();
          $refs.tr.gl();
        "
      ></FormAddStock>
    </app-modal-basic>

    <app-modal-basic ref="dFormTransGoods">
      <FormTransGood
        ref="formTransGood"
        @submitted="
          $refs.dFormTransGoods.hide();
          $refs.tr.gl();
        "
      ></FormTransGood>
    </app-modal-basic>

    <app-modal-basic ref="dConfSto">
      <FormConfigureStock
        ref="confStock"
        @submitted="
          $refs.tr.gl();
          $refs.dConfSto.hide();
        "
        :stores="stores"
      ></FormConfigureStock>
    </app-modal-basic>

    <app-modal-basic ref="dFormUse">
      <FormUse
        ref="formUse"
        @submitted="
          $refs.tr.gl();
          $refs.dFormUse.hide();
        "
      ></FormUse>
    </app-modal-basic>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import FormStore from "../stores/Form";
import FormAddStock from "./FormToAdd";
import FormTransGood from "./FormTransGood";
import FormConfigureStock from "./FormConfigureStock";
// import { AdminService } from "../../admin-module";
import { mapState } from "vuex";
import SpanStock from "./SpanStock.vue";
import FormUse from "./FormUse";

export default {
  components: {
    FormStore,
    FormAddStock,
    FormTransGood,
    FormConfigureStock,
    SpanStock,
    FormUse
  },
  data: () => ({
    list: {}
    // stores: []
  }),
  computed: {
    ...mapState({
      stores: (s) => s.logistic.stores,
      config: (s) => s.config.logistic.stock,
      user: (s) => s.user
    })
  },
  methods: {
    getList({ page, search }) {
      return new Promise((rsv) => {
        LogisticService.getProductStock({
          page,
          search,
          store_id: this.user.store_id
        }).then((res) => {
          this.list = res;
          rsv(res);
        });
      });
    }
  },
  created() {
    this.$store.dispatch("logistic/getStores");
  }
};
</script>

<style></style>
